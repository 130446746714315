/*------- 13. Our Process style ------ */
.contactus-area {
  background: url('/assets/img/bg/sec_04.webp') center 125px no-repeat;
}
.contactus-content {
  h5 {
    font-weight: 500;
    font-size: 18px;
    color: $theme-color;
    margin: 0;
    letter-spacing: 0.3px;
  }
  h1 {
    font-size: 38px;
    font-weight: 200;
    letter-spacing: 0.3px;
    margin: 8px 0 0px;
    color: #ffffff;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 54px;
  }
  p {
    font-size: 25px;
    color: #ffffff;
    line-height: 26px;
    width: 70%;
    margin: 0 auto;
    @media #{$lg-layout} {
      width: 80%;
    }
    @media #{$md-layout} {
      width: 95%;
    }
    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
}

.contact-social {
  margin-top: 58px;
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 17px;

    color: #4d4d4d;
  }
  ul {
    li {
      display: inline-block;

      margin: 0 10px;
      a {
        font-size: 16px;

        color: #4d4d4d;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.contact-form {
  padding: 20px 20px 20px 20px;

  @media #{$lg-layout} {
    padding: 50px 50px 50px 50px;
  }
  @media #{$md-layout} {
    padding: 50px 30px 50px 30px;
  }
  @media #{$xs-layout} {
    padding: 50px 30px 50px 30px;
  }
  .contact-title {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 36px;

      color: #464646;
    }
  }
  .contact-form-style {
    input,
    textarea {
      height: 40px;
      margin-bottom: 30px;
      padding: 2px 14px;

      color: #dfdfdf;
      border: none;
      border-bottom: 1px solid $theme-color;
      background: transparent;
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: $theme-color;
      opacity: 1;
    
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: #dfdfdf !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    textarea {
      height: 125px;
      margin-bottom: 0;
      padding: 10px 14px;
    }
    button {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;

      margin: 30px auto;
      padding: 15px 52px;

      text-transform: uppercase;

      color: #fff;
      border: none;
      background-color: $theme-color;
      &:hover {
        background-color: $theme-color;
      }
    }
  }
  p {
    color: #333;
    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
    }
  }
  input {
    & div {
      background-color: #000000;
    }
  }
}
.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
