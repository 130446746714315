/*------- 13. Our Process style ------ */
.ourprocess-area {
  background: url('/assets/img/bg/sec_03.webp') center 125px no-repeat;
}
.ourprocess-content {
  h5 {
    font-weight: 500;
    font-size: 18px;
    color: $theme-color;
    margin: 0;
    letter-spacing: 0.3px;
  }
  h1 {
    font-size: 38px;
    font-weight: 200;
    letter-spacing: 0.3px;
    margin: 8px 0 30px;
    color: #ffffff;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 54px;
  }
  p {
    font-size: 20px;
    color: #ffffff;
    line-height: 26px;
    width: 70%;
    margin: 0 auto;
    @media #{$lg-layout} {
      width: 80%;
    }
    @media #{$md-layout} {
      width: 95%;
    }
    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
}
